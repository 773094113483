<template>
  <div class="inputs-address">
    <v-text-field
      v-model="address.zip"
      :disabled="disabled"
      :error-messages="errorMessages('zip')"
      :label="$t('Zip code')"
      class="zip"
      outlined
    />
    <v-text-field
      v-model="address.city"
      :disabled="disabled"
      :error-messages="errorMessages('city')"
      :label="$t('City')"
      class="city"
      outlined
    />
    <v-text-field
      v-model="address.street"
      :disabled="disabled"
      :error-messages="errorMessages('street')"
      :label="$t('Street, building, office etc')"
      class="street"
      outlined
    />
  </div>
</template>

<script>
export default {
  name: 'InputAddress',
  props: {
    address: {
      type: Object,
      default () {
        return {
          zip: '',
          city: '',
          street: '',
        }
      },
    },
    disabled: {type: Boolean},
    invalidFeedback: {
      type: Object,
      default () {
        return {}
      },
    },
    invalidFeedbackNamespace: {type: String, default: ''},
  },
  methods: {
    errorMessages (field) {
      return this.invalidFeedback[`${this.invalidFeedbackNamespace}.${field}`]
    },
  },
}
</script>

<style scoped>
  .inputs-address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 1.5rem;
    grid-template-areas: 'zip city' 'street street';
  }
  .zip {
    grid-area: zip;
  }
  .city {
    grid-area: city;
  }
  .street {
    grid-area: street;
  }
</style>
