<template>
  <div>
    <v-btn
      :ripple="false"
      :small="small"
      :x-small="xSmall"
      class="text-no-wrap"
      text
      @click="downloadCertificate"
    >
      <v-icon
        :small="small"
        :x-small="xSmall"
        color="primary"
        left
      >
        {{ icons.pdf }}
      </v-icon>
      {{ $t('Download certificate') }}
    </v-btn>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {mdiFilePdfOutline} from '@mdi/js'
import {saveAs} from 'file-saver'
import error401handler from '@/mixins/error-401-handler'
import {b64toBlob} from '@/util/util'

export default {
  name: 'DepositLinkCertificate',
  mixins: [error401handler],
  props: {
    deposit: {type: Object, required: true},
    small: {type: Boolean, default: false},
    xSmall: {type: Boolean, default: false}
  },
  data () {
    return {
      icons: {
        pdf: mdiFilePdfOutline
      }
    }
  },
  computed: mapState({
    isBusinessAccount: state => state.profile.accountType === 'business',
  }),
  methods: {
    ...mapActions({
      downloadCertPrv: 'deposits/dpDownloadCert',
      downloadCertBiz: 'depositsBusiness/dpDownloadCert',
    }),
    // TODO refactor — now copy-pasted Prv vs Biz with adapters
    downloadCert () {
      return (this.isBusinessAccount ? this.downloadCertBiz : this.downloadCertPrv)(...arguments)
    },

    async downloadCertificate () {
      try {
        let {data} = await this.downloadCert(this.deposit.id)
        let {data: {content}} = JSON.parse(await data.text())
        saveAs(
          b64toBlob(content, 'application/pdf'),
          `${this.deposit.name}.pdf`
        )
      } catch (e) {
        this.handleError(e)
      }
    }
  }
}
</script>
