<template>
  <div class="inputs-person-name">
    <v-text-field
      v-model="person.lastName"
      :disabled="disabled"
      :error-messages="errorMessages('lastName')"
      :hint="hint"
      :label="$t('Last name')"
      :persistent-hint="persistentHint"
      outlined
    />
    <v-text-field
      v-model="person.firstName"
      :disabled="disabled"
      :error-messages="errorMessages('firstName')"
      :label="$t('First name')"
      outlined
    />
    <v-text-field
      v-model="person.middleName"
      :disabled="disabled"
      :error-messages="errorMessages('middleName')"
      :label="$t('Middle name')"
      outlined
    />
  </div>
</template>

<script>
export default {
  name: 'InputPersonName',
  props: {
    disabled: {type: Boolean},
    hint: {type: String, default: ''},
    persistentHint: {type: Boolean},
    invalidFeedback: {
      type: Object,
      default () {
        return {}
      },
    },
    invalidFeedbackNamespace: {type: String, default: ''},
    person: {
      type: Object,
      default () {
        return {
          firstName: '',
          middleName: '',
          lastName: '',
        }
      },
    },
  },
  methods: {
    errorMessages (field) {
      return this.invalidFeedback[`${this.invalidFeedbackNamespace}.${field}`]
    },
  },
}
</script>

<style scoped>
  .inputs-person-name {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
  }
</style>
