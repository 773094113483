<template>
  <v-card class="pa-5" flat>
    <v-overlay :value="!profileLoaded" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="profileLoaded">
      <v-row>
        <v-col>
          <h2>{{ $t('Edit company info') }}</h2>
        </v-col>
      </v-row>

      <v-form @submit.prevent>
        <!-- Название -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="localCompany.name"
              :disabled="saving"
              :error-messages="invalidFeedback.name"
              :label="$t('Company name')"
              outlined
            />
          </v-col>
        </v-row>

        <!-- ИНН, КПП, ОГРН -->
        <v-row>
          <v-col>
            <v-text-field
              v-model="localCompany.ogrn"
              :disabled="saving"
              :error-messages="invalidFeedback.ogrn"
              :label="$t('Company OGRN')"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="localCompany.inn"
              :disabled="saving"
              :error-messages="invalidFeedback.inn"
              :label="$t('Company INN')"
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="localCompany.kpp"
              :disabled="saving"
              :error-messages="invalidFeedback.kpp"
              :label="$t('Company KPP')"
              outlined
            />
          </v-col>
        </v-row>

        <!-- Адрес -->
        <v-row>
          <v-col>
            <div class="mb-2">
              {{ $t('Company address') }}
            </div>
            <InputAddress
              :address="localCompany.address"
              :disabled="saving"
              :invalid-feedback="invalidFeedback"
              invalid-feedback-namespace="address"
            />
          </v-col>
        </v-row>

        <!-- Директор -->
        <v-row>
          <v-col>
            <div class="mb-2">
              {{ $t('Director name and position title') }}
            </div>
            <v-text-field
              v-model="localCompany.director.nominative.position"
              :disabled="saving"
              :error-messages="invalidFeedback['director.nominative.position']"
              :label="$t('Director position title')"
              outlined
            />
            <InputPersonName
              :disabled="saving"
              :invalid-feedback="invalidFeedback"
              :person="localCompany.director.nominative"
              invalid-feedback-namespace="director.nominative"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mb-2">
              {{ $t('Director name and position title genitive') }}
            </div>
            <v-text-field
              v-model="localCompany.director.genitive.position"
              :disabled="saving"
              :error-messages="invalidFeedback['director.genitive.position']"
              :hint="$t('Director name genitive hint')"
              :label="$t('Director position title')"
              outlined
              persistent-hint
            />
            <InputPersonName
              :disabled="saving"
              :invalid-feedback="invalidFeedback"
              :person="localCompany.director.genitive"
              invalid-feedback-namespace="director.genitive"
              persistent-hint
            />
          </v-col>
        </v-row>

        <!-- Кнопки -->
        <v-row>
          <v-col class="d-flex align-start justify-end flex-wrap">
            <v-btn
              :ripple="false"
              class="mb-3"
              color="primary"
              outlined
              rounded
              @click="$router.push({name: 'profile-company'})"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              :disabled="saving"
              :loading="saving"
              :ripple="false"
              class="ml-3"
              color="primary"
              elevation="0"
              rounded
              @click="submit"
            >
              {{ $t('Save company info') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import error401handler from '@/mixins/error-401-handler'
import InputPersonName from '@/components/InputPersonName'
import InputAddress from '@/components/InputAddress'

export default {
  name: 'ProfileCompanyEdit',
  components: {InputAddress, InputPersonName},
  mixins: [error401handler],
  data () {
    return {
      saving: false,
      localCompany: {},
      invalidFeedback: {},
    }
  },
  watch: {
    profileLoaded (val) {
      if (val) this.init()
    },
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      profileLoaded: state => state.loaders.profile.status,
    }),
    ...mapGetters({
      currencies: 'getCurrencies',
    }),
  },
  created () {
    if (this.profileLoaded) this.init()
  },
  methods: {
    ...mapMutations({
      showNotify: 'notify/show',
    }),
    ...mapActions(['updateCompany']),
    init () {
      this.localCompany = cloneDeep(this.profile.company)
      if (!this.localCompany.director) {
        this.localCompany.director = {}
      }
      if (!this.localCompany.director.nominative) {
        this.localCompany.director.nominative = {
          firstName: '',
          middleName: '',
          lastName: '',
        }
      }
      if (!this.localCompany.director.genitive) {
        this.localCompany.director.genitive = {
          firstName: '',
          middleName: '',
          lastName: '',
        }
      }
    },
    async submit () {
      this.saving = true
      try {
        await this.updateCompany(this.localCompany)
        this.showNotify({
          text: this.$t('Successfully updated company info'),
          color: 'success'
        })
        await this.$router.push({name: 'profile-company'})
      } catch (e) {
        if (!this.handleError(e) && e.response.status === 422) {
          this.invalidFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
