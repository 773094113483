<template>
  <v-container>
    <v-row justify="center">
      <v-col sm="8" md="6" lg="4">
        <v-card class="pa-5 mt-5" flat>
          <v-card-title class="text-center">
            <h1 class="mx-auto mb-5">
              {{ $t('Create account') }}
            </h1>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="submit">
              <v-text-field
                v-model="user.first_name"
                :error-messages="invalidFeedback.first_name"
                :label="$t('First name')"
                autofocus
                name="first_name"
                outlined
              />
              <v-text-field
                v-model="user.last_name"
                :error-messages="invalidFeedback.last_name"
                :label="$t('Last name')"
                name="last_name"
                outlined
              />
              <v-text-field
                v-model="user.email"
                :error-messages="invalidFeedback.email"
                :label="$t('Email')"
                autocomplete="new-email"
                type="email"
                name="email"
                outlined
              />

              <v-text-field
                v-model="user.password"
                :error-messages="invalidFeedback.password"
                :label="$t('Password')"
                autocomplete="new-password"
                name="password"
                type="password"
                outlined
              />
              <v-text-field
                v-model="passwordConfirm"
                :error-messages="invalidFeedback.passwordConfirm"
                :label="$t('Repeat password')"
                type="password"
                outlined
              />

              <p v-if="invalidFeedback.message" class="error--text">
                {{ invalidFeedback.message }}
              </p>

              <v-checkbox
                v-model="consent"
                :error-messages="invalidFeedback.consent"
                :ripple="false"
                class="mt-0"
                @change="invalidFeedback.consent = null"
              >
                <template v-slot:label>
                  <div class="caption" @click="consentClick" v-html="consentText" />
                </template>
              </v-checkbox>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    :disabled="registering"
                    :loading="registering"
                    :ripple="false"
                    class="ml-3"
                    color="primary"
                    elevation="0"
                    rounded
                    @click="submit"
                  >
                    {{ $t('Sign up') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'Register',
  mixins: [error401handler],
  data () {
    return {
      registering: false,
      user: {
        first_name: null,
        last_name: null,
        email: null,
        password: null
      },
      passwordConfirm: null,
      invalidFeedback: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        passwordConfirm: null,
        consent: null,
        message: null
      },
      consent: false,
      consentText: this.$t('RegistrationAgreement', {
        termsUrl: window.env.SUBDOMAIN_DOCS + '/docs/terms.pdf',
        privacyUrl: window.env.SUBDOMAIN_DOCS + '/docs/privacy.pdf'
      })
    }
  },
  mounted () {
    this.user.email = this.$route.query.email
    if (this.$route.query.email !== undefined) {
      this.$router.replace({query: {}})
    }
  },
  methods: {
    ...mapActions({
      registerUser: 'registerUser'
    }),
    consentClick (e) {
      if (e.target.tagName === 'A') e.stopPropagation()
    },
    clearErrors () {
      for (let key of Object.keys(this.invalidFeedback)) {
        this.invalidFeedback[key] = null
      }
    },
    validate () {
      let isValid = true

      if (!this.consent) {
        isValid = false
        this.invalidFeedback.consent =
          this.$t('You must accept our Terms of Use before proceeding')
      }
      if (this.user.password !== this.passwordConfirm) {
        isValid = false
        this.invalidFeedback.passwordConfirm =
          this.$t('Password and confirmation password dont match')
      }

      return isValid
    },
    async submit () {
      this.clearErrors()

      if (!this.validate()) {
        return false
      }

      this.registering = true
      try {
        let {data: {data, result}} = await this.registerUser(this.user)
        this.$auth.setAuthenticated(result, data)

        if (result) {
          this.$gtm.trackEvent({
            event: 'sent-form-sign-up'
          })
        }

        return this.$router.push({name: 'profile'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          if (typeof e.response.data.message === 'string') {
            this.invalidFeedback.message = e.response.data.message
          } else {
            this.invalidFeedback = {...e.response.data.message}
          }
        }
      } finally {
        this.registering = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-card__title {
    word-break: break-word;
  }
</style>
