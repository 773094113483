<template>
  <div>
    <v-overlay :value="!profileLoaded || loading" opacity="0" absolute>
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="profileLoaded && !loading">
      <v-container>
        <app-link-back-to-deposits
          :text="$t('Back to project')"
          :to="{name: 'project-edit', params: {projectId: this.projectId}}"
        />
        <h1>{{ $t('Creating new document') }}</h1>
      </v-container>

      <v-container class="deposit-container pb-0 pb-sm-3">
        <v-row>
          <v-col lg="8">
            <v-card
              :ripple="false"
              class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
              flat
            >
              <form-doc-act-edit
                v-model="docData"
                :company="company"
                :invalid-feedback="serverFeedback"
                @clear-feedback="clearFeedback"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="8">
            <v-btn
              :disabled="saving"
              :loading="saving"
              :ripple="false"
              class="ml-3"
              color="primary"
              elevation="0"
              rounded
              @click="submit"
            >
              {{ $t('Create document') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {isEmpty} from 'lodash'
import {saveAs} from 'file-saver'
import error401handler from '@/mixins/error-401-handler'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import FormDocActEdit from '@/components/FormDocActEdit'
import {today} from '@/util/dates'

/**
 * For validation
 * @param obj
 * @param prefix
 * @param acc
 * @returns {{}}
 */
const findEmptyKeys = (obj, prefix, acc = {}) => {
  for (let [key, val] of Object.entries(obj)) {
    let currentKey = prefix ? `${prefix}.${key}` : key

    if (typeof val === 'object') {
      acc = findEmptyKeys(val, currentKey, acc)
    } else if (val === '') {
      acc[currentKey] = 'Error.EmptyValue'
    }
  }
  return acc
}

export default {
  name: 'ProjectCreateDocument',
  components: {FormDocActEdit, AppLinkBackToDeposits},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
    type: {type: String, required: true},
  },
  data () {
    return {
      loading: false,
      saving: false,
      docData: {
        document: {
          date: today(),
          paymentAmount: '',
          paymentAmountWords: '',
          tax: '13',
          paymentPeriodDays: '',
          paymentPeriodDaysWords: '',
          period: {
            startDate: '',
            endDate: '',
          },
          order: {
            number: '',
            date: '',
          },
          copies: '1',
          copiesWords: '',
        },
        project: {
          id: '',
          name: '',
          version: '',
          teammates: [],
        },
      },
      serverFeedback: {},
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      company: state => state.profile.company,
      documentTypes: state => state.config.app.docs.types,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    projectSource () {
      return this.getProjectById(this.projectId)
    },
    documentType () {
      return this.documentTypes[this.type]
    },
  },
  async created () {
    if (!this.projectSource) {
      this.loading = true
      try {
        await this.loadProjects()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    }

    if (this.projectSource) {
      this.docData.project.id = this.projectSource.id
      this.docData.project.name = this.projectSource.name
      this.docData.project.teammates = this.projectSource.teammates.map(tm => {
        return {
          accountId: tm.account_id,
          name: `${tm.user.last_name} ${tm.user.first_name} ${tm.user.middle_name ?? ''}`,
          position: '',
        }
      })
      this.docData.document.copies = (this.projectSource.teammates.length + 1).toString()
    } else {
      this.setError({
        mode: 'global',
        title: this.$t('Project not found'),
      })
    }
  },
  methods: {
    ...mapActions({
      loadProjects: 'projectsBusiness/prLoad',
      createDocument: 'projectsBusiness/prCreateDocument',
    }),
    clearFeedback (key) {
      if (this.serverFeedback[key]) {
        this.$delete(this.serverFeedback, key)
      }
    },
    validate () {
      this.serverFeedback = findEmptyKeys(this.docData)
      return isEmpty(this.serverFeedback)
    },
    async submit () {
      this.serverFeedback = {}

      this.saving = true
      try {
        let pdf = await this.createDocument({type: this.type, doc: this.docData})
        saveAs(pdf, `${this.type}-${this.docData.document.date}.pdf`)
        return this.$router.push({
          name: 'project-edit',
          params: {projectId: this.projectId}
        })
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          console.log({e})
          let {data} = e.response
          if (data instanceof Blob) {
            data = JSON.parse(await data.text())
          }
          this.serverFeedback = data.message
        }
      } finally {
        this.saving = false
      }
    },
  }
}
</script>
