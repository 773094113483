<template>
  <div>
    <v-overlay :value="loading" opacity="0" absolute>
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="!loading">
      <v-container>
        <app-link-back-to-deposits
          :text="$t('Back to projects')"
          :to="{name: 'projects'}"
        />
        <h1>
          {{ $t('Edit') }}
          {{ project.name }}
        </h1>
      </v-container>

      <template v-if="profileLoaded && projectSource">
        <v-container class="deposit-container pb-0 pb-sm-3">
          <v-row>
            <v-col lg="7">
              <v-card
                :ripple="false"
                class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
                flat
              >
                <v-form v-model="valid">
                  <form-project-business
                    :all-teammates="allTeammates"
                    :disabled="saving || removing"
                    :project="project"
                    :server-feedback="serverFeedback"
                    @input="input"
                  />
                  <v-row>
                    <v-col class="d-flex align-start justify-end flex-wrap">
                      <v-btn
                        :disabled="saving"
                        :loading="removing"
                        :ripple="false"
                        class="mb-3"
                        color="grey"
                        rounded
                        outlined
                        @click="remove"
                      >
                        {{ $t('Delete project') }}
                      </v-btn>
                      <v-btn
                        :disabled="removing"
                        :loading="saving"
                        :ripple="false"
                        class="ml-5"
                        color="primary"
                        elevation="0"
                        rounded
                        @click="submit"
                      >
                        {{ $t('Save changes') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>

            <v-col lg="5">
              <v-card
                :ripple="false"
                class="px-3 px-sm-6 py-sm-3 px-md-12 py-md-6"
                flat
              >
                <v-menu offset-y>
                  <template #activator="{on, attrs}">
                    <v-btn
                      :ripple="false"
                      color="primary"
                      elevation="0"
                      rounded
                      v-on="on"
                    >
                      {{ $t('Create document') }}
                      <v-icon right v-text="icons.caret" />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(doc, type) in documentTypes"
                      :key="type"
                      :ripple="false"
                      :to="{name: 'project-create-document', params: {type}}"
                      class="text-decoration-none"
                    >
                      {{ doc.name }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-list>
                  <v-list-item
                    v-for="doc in documents.data"
                    :key="doc.id"
                    :ripple="false"
                    link
                    @click="downloadPdf(doc)"
                  >
                    <v-list-item-icon>
                      <v-tooltip bottom>
                        <template #activator="{on}">
                          <v-icon v-text="icons.document" v-on="on" />
                        </template>
                        <template>
                          {{ $t('Download document in PDF') }}
                        </template>
                      </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ documentTypes[doc.type].name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ dateFormat(doc.data.document.date, 'dd.mm.yyyy') }},
                        версия {{ doc.data.project.version }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Период с
                        {{ dateFormat(doc.data.document.period.startDate, 'dd.mm.yyyy') }}
                        по
                        {{ dateFormat(doc.data.document.period.endDate, 'dd.mm.yyyy') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {cloneDeep} from 'lodash'
import {mdiMenuDown, mdiFileDocumentOutline} from '@mdi/js'
import {saveAs} from 'file-saver'
import FormProjectBusiness from '@/components/FormProjectBusiness'
import AppLinkBackToDeposits from '@/components/AppLinkBackToDeposits'
import error401handler from '@/mixins/error-401-handler'
import {dateFormat} from '@/util/dates'

export default {
  name: 'ProjectEdit',
  components: {AppLinkBackToDeposits, FormProjectBusiness},
  mixins: [error401handler],
  props: {
    projectId: {type: [Number, String], required: true},
  },
  data () {
    return {
      project: {},
      serverFeedback: {
        name: null
      },
      valid: true,
      loading: false,
      saving: false,
      removing: false,
      documents: [],
      icons: {
        caret: mdiMenuDown,
        document: mdiFileDocumentOutline,
      },
    }
  },
  computed: {
    ...mapState({
      profileLoaded: state => state.loaders.profile.status,
      allTeammates: state => state.profile.company?.teammates,
      documentTypes: state => state.config.app.docs.types,
    }),
    ...mapGetters({
      getProjectById: 'projectsBusiness/getProjectById',
    }),
    projectSource () {
      return this.getProjectById(this.projectId)
    },
  },
  async created () {
    if (!this.projectSource) {
      this.loading = true
      try {
        await this.loadProjects()
        await this.loadDocs(11)
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    }

    if (this.projectSource) {
      this.project = cloneDeep(this.projectSource)
    } else {
      this.setError({
        mode: 'global',
        title: this.$t('Project not found'),
      })
    }
  },
  async mounted () {
    this.documents = await this.loadDocs(this.projectId)
  },
  methods: {
    dateFormat,
    ...mapMutations({
      setError: 'error/set',
      showNotify: 'notify/show',
    }),
    ...mapActions({
      loadProjects: 'projectsBusiness/prLoad',
      updateProject: 'projectsBusiness/prUpdate',
      removeProject: 'projectsBusiness/prRemove',
      loadDocs: 'projectsBusiness/prDocs',
      downloadDocumentPdf: 'projectsBusiness/prDownloadDocumentPdf',
    }),
    input (value) {
      this.project = value
    },
    clearErrors () {
      for (let key of Object.keys(this.serverFeedback)) {
        this.serverFeedback[key] = null
      }
    },
    async submit () {
      this.saving = true
      this.clearErrors()
      let project = {
        id: this.project.id,
        name: this.project.name,
        teammates: this.project.teammates
          .filter(tm => {
            return tm.user.contributionWeight &&
              tm.user.contributionWeight !== '0'
          })
          .map(tm => ({
            account_id: tm.account_id,
            contributionWeight: tm.user.contributionWeight,
          }))
      }
      try {
        await this.updateProject(project)
        this.showNotify({
          text: this.$t('ProjectUpdated', {title: project.name}),
          color: 'success'
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.saving = false
      }
    },
    async remove () {
      let title = this.project.name
      this.removing = true
      try {
        await this.removeProject(this.project.id)
        this.showNotify({
          text: this.$t('ProjectDeleted', {title}),
          color: 'warning',
          timeout: 15000
        })
        await this.$router.push({name: 'projects'})
      } catch (e) {
        if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = {...e.response.data.message}
        }
      } finally {
        this.removing = false
      }
    },
    async downloadPdf (doc) {
      try {
        let {data} = await this.downloadDocumentPdf(doc.id)
        saveAs(data, `${doc.type}-${doc.data.document.date}.pdf`)
      } catch (e) {
        this.handleError(e)
      }
    },
  },
}
</script>
