<template>
  <div class="document-template">
    <h2>
      Акт<br>
      приема-передачи<br>
      программы для электронных вычислительных машин
      «<form-doc-input
        ref="docInput"
        v-model="docData.project.name"
        :invalid-feedback="invalidFeedback['project.name']"
        @change="$emit('clear-feedback', 'project.name')"
      />»,<br>
      версия
      <form-doc-input
        ref="docInput"
        v-model="docData.project.version"
        :invalid-feedback="invalidFeedback['project.version']"
        placeholder="1.0"
        @change="$emit('clear-feedback', 'project.version')"
      />
      по Служебному заданию, утвержденному Приказом<br>
      №
      <form-doc-input
        v-model="docData.document.order.number"
        :invalid-feedback="invalidFeedback['document.order.number']"
        placeholder="___"
        @change="$emit('clear-feedback', 'document.order.number')"
      />
      от
      <form-doc-input-date
        v-model="docData.document.order.date"
        :invalid-feedback="invalidFeedback['document.order.date']"
        @change="$emit('clear-feedback', 'document.order.date')"
      />
    </h2>
    <div class="d-flex justify-space-between mb-3">
      <div>г. Москва</div>
      <div>
        <form-doc-input-date
          v-model="docData.document.date"
          :invalid-feedback="invalidFeedback['document.date']"
          @change="$emit('clear-feedback', 'document.date')"
        />
      </div>
    </div>
    <p>
      <form-doc-input v-model="company.name" :to="toCompanyEdit" disabled />,
      именуемое в дальнейшем «Работодатель» и (или) «Общество»,
      в лице Генерального директора
      <form-doc-input v-model="directorFullNameGenitive" :to="toCompanyEdit" disabled />,
      действующего на основании Устава, с одной стороны
      и Граждане Российской Федерации, являющиеся работниками Общества:
    </p>
    <table>
      <thead>
        <tr>
          <th>№<br>п.п.</th>
          <th>ФИО работника</th>
          <th>Должность работника</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(teammate, i) in docData.project.teammates">
          <td>{{ i + 1 }}.</td>
          <td>{{ teammate.name }}</td>
          <td>
            <form-doc-input
              v-model="teammate.position"
              :invalid-feedback="invalidFeedback[`project.teammates.${i}.position`]"
              placeholder="Разработчик"
              @change="$emit('clear-feedback', `project.teammates.${i}.position`)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      именуемые в дальнейшем «Работники», с другой стороны, далее совместно
      именуемые «Стороны», а по отдельности «Сторона», составили настоящий
      Акт приема-передачи программы для электронных вычислительных машин
      <span class="nowrap">«<form-doc-input
        v-model="docData.document.name"
        :placeholder="docData.project.name"
        disabled
      />»,</span>
      версия
      <form-doc-input
        v-model="docData.project.version"
        placeholder="1.0"
        disabled
      />,
      по служебному заданию, утвержденному Приказом №
      <form-doc-input :placeholder="docData.document.order.number" disabled />
      от
      <form-doc-input-date v-model="docData.document.order.date" disabled />
      (далее по тексту – «Акт»/«Служебное задание» соответственно) о нижеследующем:
    </p>
    <ol>
      <li>
        В соответствии со Служебным заданием, в период с
        <form-doc-input-date
          ref="docInput"
          v-model="docData.document.period.startDate"
          :invalid-feedback="invalidFeedback['document.period.startDate']"
          @change="$emit('clear-feedback', 'document.period.startDate')"
        />
        по
        <form-doc-input-date
          ref="docInput"
          v-model="docData.document.period.endDate"
          :invalid-feedback="invalidFeedback['document.period.endDate']"
          @change="$emit('clear-feedback', 'document.period.endDate')"
        />,
        Работники выполнили работы по разработке,
        переработке (модернизации) комплекса программ для электронных вычислительных
        машин (ЭВМ) и баз данных (далее по тексту – «Работы») и создали следующее
        служебное произведение: программа для ЭВМ
        <span class="nowrap">«<form-doc-input
          :placeholder="docData.project.name"
          disabled
        />»</span>
        (далее по тексту – «Программное обеспечение»).
      </li>
      <li>
        По итогам тестовой эксплуатации и приемки Программного обеспечения
        Сторонами установлено, что Программное обеспечение полностью соответствует
        требованиям и характеристикам, указанным в Служебном задании.
      </li>
      <li>
        В соответствии со Служебным заданием, Работники передали, а Работодатель
        принял результат Работ – Программное обеспечение (в виде исходного текста
        и объектного кода). Результат указанных в настоящем пункте Акта Работ
        передан Работниками Работодателю в электронном виде путем его размещения
        Работниками в репозитории Работодателя, расположенном на ftp-сервере
        Работодателя в сети Интернет.
      </li>
      <li>
        Исключительное право на Программное обеспечение, в соответствии
        со статьей 1295 Гражданского кодекса Российской Федерации,
        передано (отчуждено) Работниками Работодателю и принадлежит
        Работодателю в полном объеме с даты подписания настоящего Акта.
      </li>
      <li>
        Работники не сохраняют за собой какие-либо права на использование
        Программного обеспечения самостоятельно и не вправе предоставлять
        какие-либо права на его использование третьим лицам.
      </li>
      <li>
        Вознаграждение каждого из Работников, указанных в преамбуле
        настоящего Акта, за передачу (отчуждение) в пользу Работодателя
        исключительного права на Программное обеспечение составляет
        <form-doc-input
          ref="docInput"
          v-model="docData.document.paymentAmount"
          :invalid-feedback="invalidFeedback['document.paymentAmount']"
          placeholder="___"
          @change="$emit('clear-feedback', 'document.paymentAmount')"
        />
        <span class="nowrap"> (<form-doc-input
          ref="docInput"
          v-model="docData.document.paymentAmountWords"
          :invalid-feedback="invalidFeedback['document.paymentAmountWords']"
          placeholder="сумма прописью"
          @change="$emit('clear-feedback', 'document.paymentAmountWords')"
        />)</span>
        рублей, включая НДФЛ
        <form-doc-input
          ref="docInput"
          v-model="docData.document.tax"
          :invalid-feedback="invalidFeedback['document.tax']"
          placeholder="13"
          @change="$emit('clear-feedback', 'document.tax')"
        />%.
        Работодатель выплачивает Работникам вознаграждение,
        указанное в настоящем пункте Акта, в течение
        <form-doc-input
          ref="docInput"
          v-model="docData.document.paymentPeriodDays"
          :invalid-feedback="invalidFeedback['document.paymentPeriodDays']"
          placeholder="___"
          @change="$emit('clear-feedback', 'document.paymentPeriodDays')"
        />
        <span class="nowrap"> (<form-doc-input
          ref="docInput"
          v-model="docData.document.paymentPeriodDaysWords"
          :invalid-feedback="invalidFeedback['document.paymentPeriodDaysWords']"
          placeholder="число прописью"
          @change="$emit('clear-feedback', 'document.paymentPeriodDaysWords')"
        />)</span>
        рабочих дней с даты подписания Акта
        путем перечисления денежных средств на банковские счета, указанные
        Работниками для перечисления заработной платы.
      </li>
      <li>
        Акт составлен в
        <form-doc-input
          ref="docInput"
          v-model="docData.document.copies"
          :invalid-feedback="invalidFeedback['document.copies']"
          placeholder="___"
          @change="$emit('clear-feedback', 'document.copies')"
        />
        <span class="nowrap"> (<form-doc-input
          ref="docInput"
          v-model="docData.document.copiesWords"
          :invalid-feedback="invalidFeedback['document.copiesWords']"
          placeholder="прописью"
          @change="$emit('clear-feedback', 'document.copiesWords')"
        />)</span>
        экземплярах, по 1 (Одному) экземпляру для каждой из Сторон.
      </li>
    </ol>
    <h3>Подписи сторон</h3>
    <h4>Работодатель</h4>
    <p>{{ company.name }}</p>
    <p>
      Генеральный директор
      <br><br>
      __________________________ /
      <form-doc-input v-model="directorShortName" :to="toCompanyEdit" disabled />
      /<br>
      м.п.
    </p>
    <h4>Работники</h4>
    <table>
      <thead>
        <tr>
          <th>№<br>п.п.</th>
          <th>ФИО работника</th>
          <th>Должность работника</th>
          <th>Подпись, дата</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(teammate, i) in docData.project.teammates">
          <td>{{ i + 1 }}.</td>
          <td>{{ teammate.name }}</td>
          <td>{{ teammate.position }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FormDocInput from '@/components/FormDocInput'
import FormDocInputDate from '@/components/FormDocInputDate'
import rubles from '@/util/rubles'

export default {
  name: 'FormDocActEdit',
  components: {FormDocInputDate, FormDocInput},
  model: {
    prop: 'docData',
    event: 'change',
  },
  props: {
    company: {type: Object, required: true},
    docData: {type: Object, required: true},
    invalidFeedback: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      toCompanyEdit: {name: 'profile-company-edit'},
    }
  },
  computed: {
    directorFullNameGenitive () {
      let {firstName, middleName, lastName} = this.company.director.genitive
      return `${lastName} ${firstName} ${middleName}`
    },
    directorShortName () {
      let {firstName, middleName, lastName} = this.company.director.nominative
      return `${lastName} ${firstName[0]}.${middleName[0]}.`
    },
  },
  methods: {
    // TODO для этого ещё нужно склонение числительных
    numToString (num) {
      num = Number(num)
      if (!num || !Number.isInteger(num)) return '___'
      return rubles(num)
    },
  },
}
</script>

<style lang="scss" scoped>
  .document-template {
    font: 1rem/1.5 'Times New Roman', serif;
  }

  h2 {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  h2, h3 {
    text-align: center;
  }

  ol {
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;

    & th,
    & td {
      border: 1px solid black;
      padding: 0 0.5rem;
    }

    & td:first-child {
      text-align: center;
    }
  }

  .nowrap {
    white-space: nowrap;
  }
</style>
