<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          :error-messages="feedback('name')"
          :label="$t('Project title')"
          :loading="saving"
          :rules="rules.name"
          :value="project.name"
          autocomplete="off"
          clearable
          outlined
          @input="input('name', $event)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="7">
        <h3 class="mt-12 mb-6">
          {{ $t('Teammates’ default contribution weights') }}
        </h3>
      </v-col>
      <v-col v-if="localProject.teammates.length" cols="auto" lg="5">
        <form-contribution-weight-mode
          :value="contributionWeightMode"
          @change="changeContributionWeightMode"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p v-if="localProject.teammates.length === 0" class="grey--text">
          {{ $t('You have no teammates yet.') }}
        </p>
        <v-list-item-group v-else>
          <v-list-item
            v-for="tm in localProject.teammates"
            :key="tm.user.pds_id"
            :ripple="false"
          >
            <v-list-item-icon>
              <app-avatar :image="tm.user.avatar" size="32" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ tm.user.first_name }}
                {{ tm.user.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tm.user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <form-contribution-weight
                :disabled="disabled"
                :feedback="feedback('contributionWeight')"
                :mode="contributionWeightMode"
                :value="tm.user.contributionWeight"
                @change="input('contributionWeight', $event, tm)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash'
import {convertDecimalToFraction} from '@/util/util'
import AppAvatar from '@/components/AppAvatar'
import FormContributionWeightMode from '@/components/FormContributionWeightMode'
import FormContributionWeight from '@/components/FormContributionWeight'

export default {
  name: 'FormProjectBusiness',
  components: {FormContributionWeight, AppAvatar, FormContributionWeightMode},
  props: {
    allTeammates: {type: Array, required: true},
    disabled: {type: Boolean, default: false},
    project: {
      type: Object,
      default () {
        return {id: null, name: '', teammates: []}
      }
    },
    serverFeedback: {type: Object, required: true},
  },
  data () {
    return {
      resetKey: Date.now(),
      localProject: {id: null, name: '', teammates: []},
      saving: false,
      removing: false,
      contributionWeightMode: '',
      rules: {
        name: [
          v => !!v || this.$t('Project name is required')
        ],
      }
    }
  },
  computed: {
    isDeleteAllowed () {
      let hasDeposits = this.project.depositsCount?.registered > 0 ||
        this.project.depositsCount?.inProgress > 0 ||
        this.project.depositsCount?.draft > 0
      return this.project.id && !hasDeposits
    },
  },
  created () {
    this.localProject = cloneDeep(this.project)

    let cwString = this.localProject.teammates[0]?.user.contributionWeight
    this.contributionWeightMode = /^\d+\/\d+$/.test(cwString)
      ? 'fraction'
      : 'percent'

    let freeTeammates = this.allTeammates?.filter(tm => {
      return !this.project.teammates.find(a => a.account_id === tm.account_id)
    })?.map(tm => ({
      account_id: tm.account_id,
      user: {
        ...tm.user,
        contributionWeight: '0'
      }
    }))
    this.localProject.teammates = [
      ...this.localProject.teammates,
      ...freeTeammates,
    ]
    this.localProject.teammates.sort((tm1, tm2) => {
      return tm2.user.contributionWeight - tm1.user.contributionWeight
    })
  },
  methods: {
    feedback (field) {
      if (Array.isArray(this.serverFeedback[field])) {
        return this.serverFeedback[field].join(' ')
      }
      return this.serverFeedback[field] || ''
    },
    input (field, value, teammate) {
      value = value?.trim()

      if (field === 'name') {
        this.localProject.name = value || ''
      }
      if (field === 'contributionWeight') {
        if (this.contributionWeightMode === 'percent') {
          value = Number(value)
          value = value === 100 ? '1.0' : (value / 100).toString()
        } else {
          value = value?.replace(/\s+/g, '')
        }

        let i = this.localProject.teammates.findIndex(tm => {
          return tm.account_id === teammate.account_id
        })
        if (i !== -1) {
          this.localProject.teammates[i].user.contributionWeight = value
        }
      }
      this.$emit('input', cloneDeep(this.localProject))
    },
    changeContributionWeightMode (mode) {
      this.contributionWeightMode = mode
      this.localProject.teammates.forEach(tm => {
        // TODO слишком запутано, переделать
        if (mode === 'percent') {
          let cwHasSlash = /^\d+\/\d+$/.test(tm.user.contributionWeight)
          if (cwHasSlash) {
            let [num, den] = tm.user.contributionWeight.split('/')
            num = Number(num)
            den = Number(den)
            if (isNaN(num) || isNaN(den) || num === 0 || den === 0) {
              tm.user.contributionWeight = '0'
            } else {
              tm.user.contributionWeight = (num / den).toString().substr(0, 4)
            }
          } else {
            tm.user.contributionWeight = '0'
          }
        } else {
          tm.user.contributionWeight =
            convertDecimalToFraction(tm.user.contributionWeight)
        }
      })
      this.$emit('input', cloneDeep(this.localProject))
    },
  }
}
</script>
