import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthComplete from './views/AuthComplete.vue'
import {navGuard} from './plugins/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {name: 'profile'}
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(
      /* webpackChunkName: "login" */
      './views/Login.vue'
      ),
    meta: {auth: false}
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(
      /* webpackChunkName: "register" */
      './views/Register.vue'
      ),
    meta: {auth: false}
  },
  {
    path: '/authorization_complete',
    name: 'auth-complete',
    component: AuthComplete
  },
  {
    path: '/profile',
    component: () => import(
      /* webpackChunkName: "profile" */
      './views/Profile.vue'
      ),
    children: [
      {
        path: '/',
        name: 'profile',
        component: () => import(
          /* webpackChunkName: "profile" */
          './views/ProfileBasic.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'edit',
        name: 'profile-basic-edit',
        component: () => import(
          /* webpackChunkName: "profile" */
          './views/ProfileBasicEdit.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'change-password',
        name: 'profile-password',
        component: () => import(
          /* webpackChunkName: "profile" */
          './views/ProfilePassword.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'subscription',
        name: 'profile-subscription',
        component: () => import(
          /* webpackChunkName: "profile" */
          './views/ProfileSubscription.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'add-payment-method',
        name: 'profile-add-payment-method',
        component: () => import(
          /* webpackChunkName: "profile" */
          './views/ProfileAddPaymentMethod.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'teammates',
        name: 'profile-teammates',
        component: () => import(
          /* webpackChunkName: "profile-b2b" */
          './views/ProfileTeammates.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'company',
        name: 'profile-company',
        component: () => import(
          /* webpackChunkName: "profile-b2b" */
          './views/ProfileCompany.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'company/edit',
        name: 'profile-company-edit',
        component: () => import(
          /* webpackChunkName: "profile-b2b" */
          './views/ProfileCompanyEdit.vue'
          ),
        meta: {auth: true}
      },
    ]
  },
  {
    path: '/new',
    name: 'new-deposit-select-type',
    component: () => import(
      /* webpackChunkName: "deposit-new" */
      './views/DepositNewSelectType.vue'
      ),
    meta: {auth: true}
  },
  {
    path: '/new/:type',
    name: 'new-deposit',
    component: () => import(
      /* webpackChunkName: "deposit-new" */
      './views/DepositNew.vue'
      ),
    meta: {auth: true}
  },
  {
    path: '/projects',
    component: () => import(
      /* webpackChunkName: "deposits" */
      './views/Projects.vue'
      ),
    children: [
      {
        path: '/',
        name: 'projects',
        component: () => import(
          /* webpackChunkName: "deposits" */
          './views/ProjectsList.vue'
          ),
        meta: {auth: true}
      },
      {
        path: 'add',
        name: 'project-add',
        component: () => import(
          /* webpackChunkName: "deposits" */
          './views/ProjectAdd.vue'
          ),
        meta: {auth: true}
      },
      {
        path: ':projectId/edit',
        name: 'project-edit',
        component: () => import(
          /* webpackChunkName: "deposits" */
          './views/ProjectEdit.vue'
          ),
        props: true,
        meta: {auth: true}
      },
      {
        path: ':projectId/create-document/:type',
        name: 'project-create-document',
        component: () => import(
          /* webpackChunkName: "deposits" */
          './views/ProjectCreateDocument.vue'
          ),
        props: true,
        meta: {auth: true}
      },
    ]
  },
  {
    path: '/project/:projectId/deposits/:sectionId',
    name: 'deposits',
    component: () => import(
      /* webpackChunkName: "deposits" */
      './views/Deposits.vue'
      ),
    props: true,
    meta: {auth: true}
  },
  {
    path: '/project/:projectId/deposit/:depositId',
    name: 'deposit-details',
    component: () => import(
      /* webpackChunkName: "deposit-details" */
      './views/DepositDetails.vue'
      ),
    props: true,
    meta: {auth: true}
  },
  {
    path: '/project/:projectId/deposit/:depositId/edit',
    name: 'deposit-edit',
    component: () => import(
      /* webpackChunkName: "deposit-edit" */
      './views/DepositEdit.vue'
      ),
    props: true,
    meta: {auth: true}
  },
  {
    path: '*',
    name: '404',
    component: () => import(
      /* webpackChunkName: "pagenotfound" */
      './views/PageNotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach(navGuard)

export default router
