<template>
  <v-app-bar
    absolute
    app
    dark
    flat
    prominent
  >
    <v-container class="py-0 fill-height">
      <v-row align="stretch" class="fill-height" no-gutters>
        <app-logo />

        <v-spacer />

        <v-col cols="auto" class="d-flex align-center">
          <v-btn
            v-if="ready && $route.name !== 'auth-complete'"
            :href="loginUrl"
            :ripple="false"
            data-cy="login-button"
            dark
            text
          >
            {{ $t('Login') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'
import AppLogo from '@/components/AppLogo'

export default {
  name: 'AppNavAnon',
  components: {AppLogo},
  data () {
    return {
      loginUrl: window.env.IPCHAIN_LOGIN_URL
    }
  },
  computed: {
    ...mapState({
      ready: state => state.ready,
    })
  }
}
</script>
