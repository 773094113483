<template>
  <v-container>
    <v-row justify="center" justify-sm="start">
      <v-col cols="auto">
        <h1 class="ml-sm-4 ml-md-0">
          {{ $t('Adding new deposit') }}
        </h1>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        v-for="depositType in depositTypes"
        :key="depositType.id"
        class="d-flex"
        cols="auto"
      >
        <v-hover v-slot:default="{hover}">
          <v-card
            :disabled="depositType.disabled"
            :elevation="hover ? 0 : 0"
            :to="{name: 'new-deposit', params: {type: depositType.id}}"
            :ripple="false"
            class="px-4 py-5 d-flex flex-column"
            max-width="345"
            dark
          >
            <div class="icon-dark-plate mx-2 my-4">
              <v-icon v-text="icons[depositType.id]" />
            </div>

            <h4 class="mt-10 mx-4">
              {{ depositType.title }}
            </h4>
            <v-card-text class="flex-grow-1">
              {{ depositType.text }}
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="depositType.disabled"
                color="primary"
                elevation="0"
                block
                outlined
                rounded
              >
                {{ $t('Coming soon') }}
              </v-btn>
              <v-btn
                v-else
                :to="{name: 'new-deposit', params: {type: depositType.id}}"
                class="btn-dark-border-gradient"
                elevation="0"
                block
                dark
                rounded
              >
                {{ $t('Create', {object: $t(depositType.id, {context: 'accusative'})}) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import {mdiCog, mdiMonitorScreenshot, mdiScriptTextOutline} from '@mdi/js'

export default {
  name: 'DepositNewSelectType',
  data () {
    return {
      icons: {
        implementation: mdiScriptTextOutline,
        algorithm: mdiCog,
        screens: mdiMonitorScreenshot,
      },
    }
  },
  computed: {
    ...mapState({
      types: state => state.config.deposit?.types || {}
    }),
    depositTypes () {
      return Object.keys(this.types)
        .map(type => ({id: type, ...this.types[type]}))
        .filter(type => type.createInAccount)
        .sort((dt1, dt2) => dt1.order - dt2.order)
    }
  }
}
</script>

<style lang="scss" scoped>
a.v-card.v-card--link:hover {
  text-decoration: none;
}
</style>
