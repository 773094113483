import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'
import en from '@/locales/en.json'
import ru from '@/locales/ru.json'

Vue.use(VueI18Next)

i18next.init({
  lng: window.env.DEFAULT_LANG,
  fallbackLng: ['ru', 'en'],
  resources: {
    en: {translation: en},
    ru: {translation: ru},
  },
  interpolation: {
    skipOnVariables: false
  },
})

i18next.services.formatter.add('lowercase', (value, lng, options) => {
  return value.toLocaleLowerCase()
})

export const i18n = new VueI18Next(i18next)
