<template>
  <v-card class="pa-5" flat>
    <v-overlay :value="!loaded" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <v-row v-if="loaded">
      <v-col class="text-center" cols sm="4">
        <modal-avatar :value="profile.avatar" />

        <div class="text-body-2 mt-5">
          {{ profile.name }}
        </div>
        <div
          :class="[profile.verified ? 'text--secondary' : 'error--text']"
          class="text-body-2"
        >
          {{ profile.email }}
        </div>
        <div v-if="!profile.verified" class="mt-4 body-2 error--text">
          <p>{{ $t('Email not verified') }}</p>
          <v-btn
            :disabled="sending"
            :loading="sending"
            :ripple="false"
            color="primary"
            elevation="0"
            rounded
            @click="resend"
          >
            {{ $t('Resend verification link') }}
          </v-btn>
          <p v-if="resendingMessage" class="mt-3 text-caption text--secondary">
            {{ resendingMessage }}
          </p>
        </div>
      </v-col>

      <v-col>
        <template v-if="isBusinessAccount">
          <div class="text-caption text--secondary">
            {{ $t('Company') }}
          </div>
          <div>{{ profile.company.name }}</div>
        </template>
        <template v-else>
          <div class="text-caption text--secondary">
            {{ $t('Currency') }}
          </div>
          <div>{{ profile.currency }}</div>
        </template>

        <div class="mt-6 text-caption text--secondary">
          {{ $t('Role') }}
        </div>
        <div>{{ profile.role_title }}</div>

        <template v-if="!isBusinessAccount">
          <div v-if="!validated" class="mt-6">
            <div class="d-flex align-center">
              <v-icon color="error" small v-text="icons.notValidated" />
              <div class="ml-1 text-body-2 error--text">
                {{ $t('Account is not validated') }}
              </div>
            </div>

            <modal-phone-verify>
              {{ $t('Validate by phone number') }}
            </modal-phone-verify>
          </div>

          <div v-else class="mt-6">
            <div class="mt-2 d-flex align-center">
              <v-icon color="green" small v-text="icons.validated" />
              <div class="ml-1 text-body-2 green--text">
                {{ $t('Validated account') }}
              </div>
            </div>
            <div class="text-caption text--secondary">
              {{ $t('Phone number') }}
            </div>
            <div>{{ profile.phone }}</div>

            <modal-phone-verify>
              {{ $t('Validate another phone number') }}
            </modal-phone-verify>
          </div>
        </template>
      </v-col>

      <v-col cols="auto">
        <v-tooltip left open-delay="100">
          <template v-slot:activator="{on}">
            <v-btn
              v-on="on"
              :to="{name: 'profile-basic-edit'}"
              color="white"
              elevation="0"
              fab
              small
            >
              <v-icon color="grey">
                {{ icons.edit }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('Edit profile') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {mdiAccount, mdiAlertCircle, mdiCheckCircle, mdiPencilOutline} from '@mdi/js'
import ModalAvatar from '@/components/ModalAvatar'
import ModalPhoneVerify from '@/components/ModalPhoneVerify'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProfileBasic',
  components: {ModalAvatar, ModalPhoneVerify},
  mixins: [error401handler],
  data () {
    return {
      sending: false,
      resendingMessage: false,
      dialogAvatar: false,
      icons: {
        avatar: mdiAccount,
        edit: mdiPencilOutline,
        validated: mdiCheckCircle,
        notValidated: mdiAlertCircle
      }
    }
  },
  computed: {
    ...mapState({
      isBusinessAccount: state => state.profile.accountType === 'business',
      profile: state => state.profile,
      loaded: state => state.loaders.profile.status
    }),
    ...mapGetters({
      currencies: 'getCurrencies',
      avatarStyle: 'getAvatarStyle'
    }),
    validated () {
      return this.profile.validated_type === 'phone' &&
        !!this.profile.validated_at
    },
    languages () {
      return this.$i18n.i18next.languages
    },
  },
  methods: {
    ...mapActions({
      resendVerificationLink: 'resendVerificationLink',
    }),
    async resend () {
      this.sending = true
      this.resendingMessage = false
      try {
        let {data: {data}} = await this.resendVerificationLink()
        this.resendingMessage = typeof data === 'string' ? data : ''
      } catch (e) {
        this.handleError(e, {mode: 'modal'})
      } finally {
        this.sending = false
      }
    }
  }
}
</script>
