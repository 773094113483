/**
 * Format date
 * @param {String|Date} date
 * @param {String} format
 */
export const dateFormat = (date, format = 'dd.mm.yyyy') => {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  let y = date.getFullYear().toString()
  let m = (date.getMonth() + 1).toString().padStart(2, '0')
  let d = date.getDate().toString().padStart(2, '0')

  return format.replace('yyyy', y)
    .replace('mm', m)
    .replace('dd', d)
}

export const today = () => dateFormat(new Date(), 'yyyy-mm-dd')
