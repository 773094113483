import Vue from 'vue'

export default {
  load () {
    return Vue.$http.get('v2/business/projects')
  },
  get (id) {
    return Vue.$http.get(`v2/business/projects/${id}`)
  },
  create (project) {
    return Vue.$http.post('v2/business/projects/add', project)
  },
  update (project) {
    return Vue.$http.put(`v2/business/projects/${project.id}`, project)
  },
  remove (id) {
    return Vue.$http.delete(`v2/business/projects/${id}`)
  },
  docs (id) {
    return Vue.$http.get(`v2/business/projects/${id}/docs`)
  },
  createDocument (documentType, data) {
    return Vue.$http.post(`/v2/business/docs/${documentType}`, data, {
      responseType: 'blob'
    })
  },
  downloadDocumentPdf (documentId) {
    return Vue.$http.get(`/v2/business/docs/${documentId}`, {
      responseType: 'blob'
    })
  },
}
